.pagebuilder-unsaved-changes {
  text-align: center;
}

.pagebuilder-pagesettings-icon {
  font-size: 0.6em;
  cursor: pointer;
}

.pagebuilder-pagesettings-icon:hover {
  opacity: 0.5;
}

.pagebuilder-add-button {
  cursor: pointer;
  margin: 10px 0;
}

.pagebuilder-add-button:hover {
  opacity: 0.5;
}

.add-button-area {
  font-size: 1.4rem;
}

.pagebuilder-builder {
  .pagebuilder-add-button {
    color: $pagebuilderAreaBorder;
  }
}

.pagebuilder-item {
  .item-name {
    border-top-left-radius: $pagebuilderBorderRadius;
    border-top-right-radius: $pagebuilderBorderRadius;
    padding: 2px 8px;

    button {
      font-size: 0.9rem;
      padding: 0 4px;
      cursor: pointer;
      color: inherit;
    }
    button:hover {
      font-weight: bold;

      .fa {
        opacity: 0.5;
      }
    }
    button:last-of-type {
      margin-right: 5px;
    }

    .pagebuilder-draghandle {
      cursor: move !important;
    }
  }

  .disabled-button.pagebuilder-item-move-button {
    opacity: 0.2;
    cursor: default;
  }
}

.pagebuilder-sort-wrapper {
  text-align: center;
  border-radius: $pagebuilderBorderRadius;
  padding: 3px 6px;
}

.pagebuilder-area-sort-wrapper {
  background: $pagebuilderAreaBorder;
  color: $pagebuilderAreaNameColor;
}

.pagebuilder-row-sort-wrapper {
  background: $pagebuilderRowBorder;
  color: $pagebuilderRowNameColor;
}

.area-content,
.row-content,
.column-content,
.element-content {
  border-bottom-left-radius: $pagebuilderBorderRadius;
  border-bottom-right-radius: $pagebuilderBorderRadius;
}

.pagebuilder-area {
  .item-name {
    background: $pagebuilderAreaBorder;
    color: $pagebuilderAreaNameColor;
  }

  .area-content {
    background: $pagebuilderAreaBackground;
    border: 1px solid $pagebuilderAreaBorder;
    padding: 10px;
    min-height: 20px;
  }

  .pagebuilder-add-button {
    color: $pagebuilderRowBorder;
  }
}

.pagebuilder-row {
  .item-name {
    background: $pagebuilderRowBorder;
    color: $pagebuilderRowNameColor;
  }

  .row-content {
    background: $pagebuilderRowBackground;
    border: 1px solid $pagebuilderRowBorder;
    padding: 10px;
    min-height: 20px;
  }

  .pagebuilder-add-button {
    color: $pagebuilderElementBorder;
  }
}

.pagebuilder-column {
  .item-name {
    background: $pagebuilderColumnBorder;
    color: $pagebuilderColumnNameColor;
  }

  .column-content {
    background: $pagebuilderColumnBackground;
    border: 1px solid $pagebuilderColumnBorder;
    padding: 10px;
    min-height: 20px;
  }
}

.pagebuilder-element {
  .item-name {
    background: $pagebuilderElementBorder;
    color: $pagebuilderElementNameColor;
  }

  .element-content {
    background: $pagebuilderElementBackground;
    border: 1px solid $pagebuilderElementBorder;
    padding: 10px;
    min-height: 20px;
  }

  button {
    cursor: pointer;
  }
}

.is-dragging {
  :is(.pagebuilder-area, .pagebuilder-row, .pagebuilder-element) {
    margin-bottom: 5px;
  }
}

.pagebuilder-columnschema-icon {
  display: inline-block;

  .columnschema-icon-column {
    display: inline-block;
    background: #999;
    height: 25px;
    min-width: 1px;
    margin-right: 2px;
  }

  .columnschema-icon-column-1 {
    width: 1px * $pagebuilderColumnSchemaIconFactor;
  }
  .columnschema-icon-column-2 {
    width: 2px * $pagebuilderColumnSchemaIconFactor;
  }
  .columnschema-icon-column-3 {
    width: 3px * $pagebuilderColumnSchemaIconFactor;
  }
  .columnschema-icon-column-4 {
    width: 4px * $pagebuilderColumnSchemaIconFactor;
  }
  .columnschema-icon-column-5 {
    width: 5px * $pagebuilderColumnSchemaIconFactor;
  }
  .columnschema-icon-column-6 {
    width: 6px * $pagebuilderColumnSchemaIconFactor;
  }
  .columnschema-icon-column-7 {
    width: 7px * $pagebuilderColumnSchemaIconFactor;
  }
  .columnschema-icon-column-8 {
    width: 8px * $pagebuilderColumnSchemaIconFactor;
  }
  .columnschema-icon-column-9 {
    width: 9px * $pagebuilderColumnSchemaIconFactor;
  }
  .columnschema-icon-column-10 {
    width: 10px * $pagebuilderColumnSchemaIconFactor;
  }
  .columnschema-icon-column-11 {
    width: 11px * $pagebuilderColumnSchemaIconFactor;
  }
  .columnschema-icon-column-12 {
    width: 12px * $pagebuilderColumnSchemaIconFactor;
  }
  .columnschema-icon-column-13 {
    width: 13px * $pagebuilderColumnSchemaIconFactor;
  }
  .columnschema-icon-column-14 {
    width: 14px * $pagebuilderColumnSchemaIconFactor;
  }
  .columnschema-icon-column-15 {
    width: 15px * $pagebuilderColumnSchemaIconFactor;
  }
  .columnschema-icon-column-16 {
    width: 16px * $pagebuilderColumnSchemaIconFactor;
  }
  .columnschema-icon-column-17 {
    width: 17px * $pagebuilderColumnSchemaIconFactor;
  }
  .columnschema-icon-column-18 {
    width: 18px * $pagebuilderColumnSchemaIconFactor;
  }
  .columnschema-icon-column-19 {
    width: 19px * $pagebuilderColumnSchemaIconFactor;
  }
  .columnschema-icon-column-20 {
    width: 20px * $pagebuilderColumnSchemaIconFactor;
  }
  .columnschema-icon-column-21 {
    width: 21px * $pagebuilderColumnSchemaIconFactor;
  }
  .columnschema-icon-column-22 {
    width: 22px * $pagebuilderColumnSchemaIconFactor;
  }
  .columnschema-icon-column-23 {
    width: 23px * $pagebuilderColumnSchemaIconFactor;
  }
  .columnschema-icon-column-24 {
    width: 24px * $pagebuilderColumnSchemaIconFactor;
  }
}

.pagebuilder-select-schema {
  width: 100%;
  padding: 20px 0;
  text-align: center;
  cursor: pointer;
}

.selected.pagebuilder-select-schema {
  background-color: rgb(230, 230, 230);
}

.pagebuilder-select-schema:hover {
  background-color: rgb(220, 220, 220);
}

.pagebuilder-select-element {
  width: 100%;
  padding: 20px 0;
  margin-bottom: 20px;
  text-align: center;
  cursor: pointer;
}

.pagebuilder-select-element:hover {
  background-color: rgb(220, 220, 220);
}

.pagebuilder-select-element-icon {
  font-size: 1.3rem;
}

.pagebuilder-select-element-title {
  margin-top: 15px;
}

.pagebuilder-element-no-content {
  text-align: center;
  font-style: italic;
  font-size: 0.9rem;
}

.pagebuilder-element-no-preview {
  text-align: center;
  font-style: italic;
  font-size: 0.9rem;
}

.pagebuilder-element-previewbutton {
  display: block;
  width: 100%;
}

.pagebuilder-element-preview-image {
  img {
    max-height: 50px;
    width: auto;
  }
}

.pagebuilder-control-label {
  padding-top: 5px;
}

.pagebuilder-settings-section {
  .settings-section-title {
    h3 {
      margin-bottom: 0;
    }

    button {
      cursor: pointer;
    }

    button:hover {
      background-color: rgb(240, 240, 240);
      padding: 0 10px;
    }

    .caret {
      position: relative;
      left: 5px;
      top: 1px;
    }
  }
}

.trbl-picker {
  .trbl-sides-lock {
    display: block;
    width: 100%;
    cursor: pointer;
    padding: 5px 0;
    text-align: center;
  }

  .trbl-sides-lock:hover {
    opacity: 0.5;
  }
}

.pagebuilder-control-border {
  .control-border-menu {
    float: left;
    width: 50px;
    text-align: center;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    button {
      cursor: pointer;
      display: block;
      width: 100%;
      text-align: center;
      padding: 5px 0;
    }

    button:hover {
      background: rgb(240, 240, 240);
    }

    button.selected {
      border-top: 1px solid rgb(230, 230, 230);
      border-left: 1px solid rgb(230, 230, 230);
      border-bottom: 1px solid rgb(230, 230, 230);
      border-right: 1px solid white;
    }
  }

  .control-border-view {
    margin-left: 49px;
    border: 1px solid rgb(230, 230, 230);
    min-height: 180px;

    .control-border-view-content {
      padding: 10px;
    }

    .control-border-view-title {
      border-bottom: 1px solid rgb(230, 230, 230);
      margin-bottom: 7px;
    }
  }

  .control-border-preview {
    width: 200px;
    height: 100px;
    margin: auto;
    background: rgb(245, 245, 245);
  }
}

.pagebuilder-control-boxshadow {
  .control-boxshadow-preview {
    width: 200px;
    height: 100px;
    margin: auto;
    margin-bottom: 20px;
    background: rgb(245, 245, 245);
  }
}

.pagebuilder-control-icon {
  height: 210px;
  overflow: scroll;
}

.pagebuilder-control-icon-picker-button {
  cursor: pointer;
  padding: 10px;
}

.pagebuilder-control-icon-picker-button:hover,
.selected.pagebuilder-control-icon-picker-button {
  background-color: rgb(220, 220, 220);
}

.align-picker {
  .align-picker-button {
    padding: 10px;
    cursor: pointer;
  }

  .align-picker-button:hover,
  .selected.align-picker-button {
    background-color: rgb(220, 220, 220);
  }
}

/** PAGE VIEW **/

.pageview-element {
  p:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  button {
    display: inline-block;
  }
}

.pageview-button-icon-left {
  padding-right: 10px;

  .fa {
    font-size: 0.9em;
  }
}

.pageview-button-icon-right {
  padding-left: 10px;

  .fa {
    font-size: 0.9em;
  }
}

.pagebuilder-drag-over {
  width: 100%;
  height: 40px;
  border: 1px dashed green;
}

.pagebuilder-test {
  .test-sortable {
    border: 1px solid #333;
    border-radius: 10px;
    padding: 10px;
  }

  .test-sortable-area {
    background: yellow;
  }

  .test-sortable-row {
    background: greenyellow;
  }
}

.pageview-page {
  .lessongrid-item {
    padding: 10px;
  }

  a.lessongrid-item {
    display: block;
    cursor: pointer;
  }

  .page-element-image {
    img {
      display: block;
    }
  }
}
