$primaryPadding: 20px;
$pagebuilderColumnSchemaIconFactor: 2;

$pagebuilderBorderRadius: 5px;

$pagebuilderAreaBorder: #999;
$pagebuilderAreaBackground: rgb(245, 245, 245);
$pagebuilderAreaNameColor: white;

$pagebuilderRowBorder: #b4e3c1;
$pagebuilderRowBackground: #eaf9ee;
$pagebuilderRowNameColor: #333;

/*$pagebuilderColumnBorder: #f2d091;
$pagebuilderColumnBackground: #fff3df;
$pagebuilderColumnNameColor: #333;*/

$pagebuilderColumnBorder: #f2ee98;
$pagebuilderColumnBackground: #f7f7de;
$pagebuilderColumnNameColor: #333;

$pagebuilderElementBorder: #8fcbd9;
$pagebuilderElementBackground: #e3eff2;
$pagebuilderElementNameColor: white;

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/MaterialIcons-Regular.ttf);
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url(../fonts/MaterialIcons-Regular.ttf) format("truetype");
}

.mi {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

@import "./scss/pagebuilder";
